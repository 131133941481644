import * as React from "react";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { useTranslation } from "react-i18next";

export function makeTournamentInfo(game: string) {
  return function TournamentInfo({ config }: { config: any }) {
    const numberFormatter = useNumberFormatter();
    const { t } = useTranslation();
    return (
      <>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("type")}</div>
          <div className="px-2">{t(`game.${game}`)}</div>
        </div>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("turn_time")}</div>
          <div className="px-2">
            {t("seconds_value", { value: config.turnTime })}
          </div>
        </div>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("backgammon.points")}</div>
          <div className="px-2">{numberFormatter.format(config.points)}</div>
        </div>
      </>
    );
  };
}
