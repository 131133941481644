import * as React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import classNames from "classnames";
import {
  Currency,
  BackgammonTableInput,
  BackgammonTableType,
} from "../../../../graphql/generated";
import {
  BackgammonDefaultConfigQueryQuery,
  BackgammonDefaultConfigQueryQueryVariables,
} from "./graphql/BackgammonDefaultConfigQuery.generated";
import { loader } from "graphql.macro";
import { useQuery } from "../../../../hooks/useQuery";
import {
  BackgammonCreateOpenTableMutation,
  BackgammonCreateOpenTableMutationVariables,
} from "./graphql/BackgammonCreateOpenTableMutation.generated";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../Loader";
import { useMutation } from "../../../../hooks/useMutation";
import { useCustomChatContext, useUser } from "../../../UserProvider";
import styles from "./CreateTable.module.scss";
import { Input, Slider } from "../../../Slider";
import { useNumberFormatter } from "../../../../hooks/useNumberFormatter";
import { FieldContainer } from "../../common/createtable/FieldContainer";
import { SliderLabel } from "../../common/createtable/SliderLabel";
import { AbsoluteCenter } from "../../../AbsoluteCenter";
import { CombinedError } from "@urql/core/dist/types/utils/error";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { ButtonWithSound } from "../../../AudioProvider";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
  BackgammonCreateTournamentTableMutation,
  BackgammonCreateTournamentTableMutationVariables,
} from "./graphql/BackgammonCreateTournamentTableMutation.generated";
import { betByPoints } from "../utils";
import { SliderInputProps } from "@reach/slider";
import { clamp } from "../../../../utils";

const BACKGAMMON_DEFAULT_CONFIG_QUERY = loader(
  "./graphql/BackgammonDefaultConfigQuery.graphql"
);

const BACKGAMMON_CREATE_OPEN_TABLE_MUTATION = loader(
  "./graphql/BackgammonCreateOpenTableMutation.graphql"
);

const BACKGAMMON_CREATE_TOURNAMENT_TABLE_MUTATION = loader(
  "./graphql/BackgammonCreateTournamentTableMutation.graphql"
);

const CUBE_VALUES = [1, 2, 4, 8, 16, 32, 64];

const CubeValuesSlider = ({
  value,
  min = 0,
  max = min * CUBE_VALUES[CUBE_VALUES.length - 1],
  step = 1,
  handleAlignment = "contain",
  onChange,
  disabled = false,
  ...rest
}: { value: number; onChange: (value: number) => void } & Omit<
  SliderInputProps,
  "value" | "defaultValue" | "onChange" | "children"
>) => {
  const safeOnChange = (newValue: number) => {
    // Since JavaScript has problems with large floats, round
    // the final value to 5 digits after the decimal point (see #4124)
    return onChange(parseFloat(newValue.toFixed(5)));
  };
  const onButtonChange = (step: number) => () => {
    const isIncreasingStep = step >= 0;
    if (isIncreasingStep) {
      safeOnChange(clamp(value * 2, min, max));
    } else {
      safeOnChange(clamp(value / 2, min, max));
    }
  };

  return (
    <div className="flex items-center w-full">
      <div className="flex-shrink-0 pr-1">
        <ButtonWithSound
          disabled={disabled}
          type="button"
          className="btn btn-green-600 btn-sm text-sm rounded-full"
          onClick={onButtonChange(step * -1)}
        >
          <FontAwesomeIcon icon={faMinus} />
        </ButtonWithSound>
      </div>
      <div className="flex-1 px-1">
        <Input
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={safeOnChange}
          handleAlignment={handleAlignment}
          disabled={disabled}
          {...rest}
        />
      </div>
      <div className="flex-shrink-0 pl-1">
        <ButtonWithSound
          disabled={disabled}
          type="button"
          className="btn btn-green-600 btn-sm text-sm rounded-full"
          onClick={onButtonChange(step)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </ButtonWithSound>
      </div>
    </div>
  );
};

const BackgammonTableConfigForm = ({
  sectionId,
  config,
  onSubmit,
}: {
  sectionId: string;
  config: NonNullable<
    BackgammonDefaultConfigQueryQuery["backgammonDefaultConfig"]
  >;
  onSubmit: (input: BackgammonTableInput) => Promise<CombinedError | null>;
}) => {
  const [error, setError] = React.useState<string>();
  const isMounted = useIsMounted();
  const { user } = useUser();
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  const { setOpenedChat } = useCustomChatContext();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        bet: Yup.number()
          .min(config.bet.minimum)
          .max(config.bet.maximum)
          // eslint-disable-next-line no-template-curly-in-string
          .test("is-within-range", "${path} is not within range", (value) => {
            return (
              value !== null &&
              value !== undefined &&
              value % config.bet.step === 0
            );
          })
          .required(),
      }),
    [config]
  );

  const sortedCommissions = React.useMemo(
    () => config.commissions.sort((a, b) => a.maxBet - b.maxBet),
    [config.commissions]
  );

  const calculatePrize = React.useCallback(
    (betValue: number, numberOfPlayers: number) => {
      for (let i = 0; i < sortedCommissions.length; i++) {
        if (betValue <= sortedCommissions[i].maxBet) {
          return (
            numberOfPlayers *
            betValue *
            (1 - sortedCommissions[i].commission / 100)
          );
        }
      }
      return 0;
    },
    [sortedCommissions]
  );

  const [prize, setPrize] = React.useState<number>();

  React.useEffect(() => {
    setPrize(calculatePrize(config.bet.default, 2));
  }, [config.bet.default, calculatePrize]);

  return (
    <Formik
      initialValues={{
        name: user.name,
        turnTime: config.turnTime.default,
        bet: config.bet.default,
        maxBet: config.bet.default,
        type: BackgammonTableType.Public,
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors: { [key: string]: string | undefined } = {};
        const availableBalance =
          user.balances.find(
            (balance) => balance.currency === Currency.BlueChips
          )?.value ?? 0;
        let multiplier = 1;
        if (values.maxBet * multiplier > availableBalance) {
          errors.bet = t("insufficient_funds");
        }
        return errors;
      }}
      onSubmit={(values) => {
        setOpenedChat(false);
        return onSubmit({
          sectionId,
          ...values,
        }).then((error) => {
          if (error != null && isMounted.current) {
            if (error.graphQLErrors.length > 0)
              setError(error.graphQLErrors[0].message);
            else if (error.networkError) setError(error.networkError.message);
          }
        });
      }}
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="flex flex-col h-full">
            {error && (
              <div className="p-4 text-center rounded bg-red-700 mb-4 flex-shrink-0">
                <span className="text-white font-bold block">{error}</span>
              </div>
            )}
            <div
              className={classNames(
                "flex-1",
                "overflow-auto",
                styles.scrollable
              )}
            >
              <FieldContainer name={t("name")}>
                <Field
                  name="name"
                  className="form-input w-full py-1 block rounded"
                />
              </FieldContainer>
              <FieldContainer name={t("turn_time")}>
                <Slider
                  disabled={isSubmitting}
                  value={values.turnTime}
                  min={config.turnTime.minimum}
                  max={config.turnTime.maximum}
                  step={config.turnTime.step}
                  onChange={(value) => {
                    setFieldTouched("turnTime", true);
                    setFieldValue("turnTime", value);
                  }}
                />
                <SliderLabel
                  value={t("seconds_value", {
                    value: numberFormatter.format(values.turnTime),
                  })}
                />
              </FieldContainer>
              <FieldContainer name={t("bet")}>
                <Slider
                  disabled={isSubmitting}
                  value={
                    typeof values.bet !== "number"
                      ? config.bet.minimum
                      : values.bet
                  }
                  min={config.bet.minimum}
                  max={config.bet.maximum}
                  step={config.bet.step}
                  onChange={(value) => {
                    setFieldTouched("bet", true);
                    setFieldValue("bet", value);
                    setPrize(calculatePrize(value, 2));
                    if (config.usingDoublingCube) {
                      setFieldTouched("maxBet", true);
                      setFieldValue("maxBet", value);
                    }
                  }}
                />
                <Field
                  disabled={isSubmitting}
                  name="bet"
                  type="number"
                  className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = Number(e.target.value);
                    if (
                      newValue >= config.bet.minimum &&
                      newValue <= config.bet.maximum
                    ) {
                      setFieldTouched("bet", true);
                      setFieldValue("bet", newValue);
                      setPrize(calculatePrize(newValue, 2));
                      if (config.usingDoublingCube) {
                        setFieldTouched("maxBet", true);
                        setFieldValue("maxBet", config.bet.minimum);
                      }
                    }
                  }}
                />
                <ErrorMessage
                  name="bet"
                  className="mt-2 font-bold text-red-600"
                  component="div"
                />
              </FieldContainer>
              <FieldContainer name={t("prize")}>
                <div className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5">
                  {prize}
                </div>
              </FieldContainer>
              {config.usingDoublingCube && (
                <FieldContainer name={t("max_bet")}>
                  <CubeValuesSlider
                    disabled={isSubmitting}
                    value={values.maxBet}
                    min={values.bet}
                    onChange={(value) => {
                      setFieldTouched("maxBet", true);
                      setFieldValue("maxBet", value);
                    }}
                  />
                  <Field
                    disabled={isSubmitting}
                    name="maxBet"
                    type="number"
                    className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5"
                  />
                  <ErrorMessage
                    name="maxBet"
                    className="mt-2 font-bold text-red-600"
                    component="div"
                  />
                </FieldContainer>
              )}
            </div>
            <ButtonWithSound
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn w-full btn-blue-600 rounded mt-4 flex-shrink-0"
            >
              {!isSubmitting ? t("create_table") : t("loading")}
            </ButtonWithSound>
          </form>
        );
      }}
    </Formik>
  );
};

function CreateOpenTable({
  sectionId,
  onTableCreated,
  onDismiss,
}: {
  sectionId: string;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) {
  const [{ data }] = useQuery<
    BackgammonDefaultConfigQueryQuery,
    BackgammonDefaultConfigQueryQueryVariables
  >({
    query: BACKGAMMON_DEFAULT_CONFIG_QUERY,
    variables: {
      sectionId,
    },
  });
  const [, createTable] = useMutation<
    BackgammonCreateOpenTableMutation,
    BackgammonCreateOpenTableMutationVariables
  >(BACKGAMMON_CREATE_OPEN_TABLE_MUTATION, false);

  const { t } = useTranslation();

  const config = data?.backgammonDefaultConfig;
  if (config === null)
    throw new Error("Game backgammon not supported on this room");

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={onDismiss}
      />
      <div
        className="w-1/3 p-4 absolute shadow-2xl bg-blue-800 top-0 bottom-0 right-0 z-30"
        aria-label={t("create_table")}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between text-white flex-shrink-0 mb-4 -mx-2">
            <span className="font-bold text-lg truncate px-2">
              {t("create_table")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={onDismiss}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-hidden relative">
            {config === undefined ? (
              <AbsoluteCenter>
                <Loader description={t("loading_game_config")} />
              </AbsoluteCenter>
            ) : (
              <BackgammonTableConfigForm
                sectionId={sectionId}
                config={config}
                onSubmit={(input) =>
                  createTable({ input }).then((result) => {
                    if (result.error) {
                      return result.error;
                    } else {
                      onTableCreated(
                        result!.data!.backgammonCreateOpenTable!.id!
                      );
                      return null;
                    }
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function CreateTournamentTable({
  onTableCreated,
  sectionId,
  onDismiss,
}: {
  sectionId: string;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) {
  const [, createTable] = useMutation<
    BackgammonCreateTournamentTableMutation,
    BackgammonCreateTournamentTableMutationVariables
  >(BACKGAMMON_CREATE_TOURNAMENT_TABLE_MUTATION, false);

  const isMounted = useIsMounted();
  React.useEffect(() => {
    createTable({ sectionId: sectionId }).then((result) => {
      if (result.error) {
        if (isMounted.current) onDismiss();
      } else onTableCreated(result!.data!.backgammonCreateTournamentTable!.id!);
    });
  }, [sectionId, createTable, onTableCreated, isMounted, onDismiss]);
  return (
    <>
      <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20" />
      <AbsoluteCenter>
        <Loader description="Creating table" />
      </AbsoluteCenter>
    </>
  );
}

export const CreateTable = ({
  isTournament,
  ...rest
}: {
  sectionId: string;
  isTournament: boolean;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) => {
  if (!isTournament) return <CreateOpenTable {...rest} />;
  else return <CreateTournamentTable {...rest} />;
};
