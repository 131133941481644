import { tableData } from "./tableData";
import { CreateTable } from "./CreateTable";
import * as React from "react";
import { makeTournamentInfo } from "./TournamentInfo";

const GameComponent = React.lazy(() => import("./Game"));

const makeDefinition = (game: string) => ({
  tableData,
  CreateTableComponent: CreateTable,
  GameComponent,
  TournamentInfoComponent: makeTournamentInfo(game),
});

const VARIANCES = ["backgammon_classic", "backgammon_portes"];

const GameConfig = VARIANCES.reduce(
  (prev, key) => ({ ...prev, [key]: makeDefinition(key) }),
  {}
);

export default GameConfig;
