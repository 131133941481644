/* eslint-disable */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormattedNumber } from "../../FormattedNumber";
import { BackgammonTableType } from "../../../graphql/generated";
import { TableDataDefinition } from "../types";
import { getEntryFee, getRequiredPlayers } from "./utils";
import { SectionTableFragmentFragment } from "../../../graphql/SectionTableFragment.generated";
import { Column } from "react-table";

type TableType = Omit<SectionTableFragmentFragment, "metaData"> & {
  metaData: {
    points: number;
    turnTime: number;
    bet: number;
    type: BackgammonTableType;
  };
};

type RowData = {
  users: number;
  points: number;
  turnTime: number;
  bet: number;
};

export const tableData: TableDataDefinition<RowData> = {
  useColumns: (isTournament: boolean) => {
    const { t } = useTranslation();
    return React.useMemo(() => {
      let result: Array<Column<RowData>> = [
        {
          Header: t<string>("backgammon.points"),
          accessor: "points",
          Cell: ({ row }) => <FormattedNumber value={row.original.points} />,
        },
        {
          Header: t<string>("turn_time"),
          accessor: "turnTime",
          Cell: ({ row }) => <FormattedNumber value={row.original.turnTime} />,
        },
      ];

      if (!isTournament)
        result = result.concat([
          {
            Header: t<string>("bet"),
            accessor: "bet",
            Cell: ({ row }) => <FormattedNumber value={row.original.bet} />,
          },
        ]);

      return result;
    }, [isTournament, t]);
  },
  makeRow: (table: TableType) => ({
    users: table.players.length,
    points: table.metaData.points,
    turnTime: table.metaData.turnTime,
    bet: table.metaData.bet,
  }),
  calculateEntryFee: (table) =>
    table.metaData.type === BackgammonTableType.Private
      ? null
      : getEntryFee(table.metaData.players, table.metaData.bet),

  getRequiredPlayers: (table) => getRequiredPlayers(table.metaData.players),
  getDetails: (table: TableType) => {
    const { t } = useTranslation();
    return (
      <>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("backgammon.points")}</div>
          <div className="px-2">
            <FormattedNumber value={table.metaData.points} />
          </div>
        </div>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("turn_time")}</div>
          <div className="px-2">
            <FormattedNumber value={table.metaData.turnTime} />
          </div>
        </div>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("bet")}</div>
          <div className="px-2">
            <FormattedNumber value={table.metaData.bet} />
          </div>
        </div>
      </>
    );
  },
};
