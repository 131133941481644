export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: string;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: string;
  /** A JSON scalar */
  JSON: any;
  /** An object scalar */
  Object: any;
  /** A file part in a multipart request */
  Upload: any;
};

export type AccountUpdate = UserUpdate & {
  userId: Scalars["ID"];
  user: User;
};

export type Activities = {
  edges: Array<ActivityEdge>;
  pageInfo: CursorPageInfo;
};

export type Activity = {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  expiry: Scalars["DateTime"];
  content: ActivityContent;
  cursor: Scalars["String"];
  actor: Maybe<Player>;
};

export type ActivityContent =
  | BalanceUpdateSendActivityContent
  | PurchaseActivityContent
  | FriendRequestAcceptedActivityContent
  | FriendRequestActivityContent
  | UserReferContent
  | UserMessageContent
  | BalanceUpdateLeaderBoardAwardContent
  | FriendshipCanceledActivityContent
  | BalanceUpdateAdjustmentActivityContent
  | BalanceUpdateTournamentAwardContent;

export type ActivityEdge = {
  node: Activity;
};

export type ActivityUpdate = UserUpdate & {
  userId: Scalars["ID"];
  activity: Activity;
};

export type Ads = {
  placementConfig: PlacementConfig;
  rewardsConfig: RewardsConfig;
  count: Maybe<Scalars["Int"]>;
  firstAdWatched: Maybe<Scalars["DateTime"]>;
  currentAdWatched: Maybe<Scalars["DateTime"]>;
};

export type AdsResponse = {
  userId: Scalars["ID"];
  count: Scalars["Int"];
  firstAdWatched: Scalars["DateTime"];
  currentAdWatched: Scalars["DateTime"];
};

export type Amount = {
  currency: Currency;
  value: Scalars["Float"];
};

export type Avatar = {
  id: Scalars["ID"];
  name: Scalars["String"];
  url: Scalars["String"];
  price: Maybe<Amount>;
  active: Scalars["Boolean"];
  photoType: Maybe<AvatarType>;
};

export enum AvatarType {
  CustomPhoto = "CUSTOM_PHOTO",
  Avatar = "AVATAR",
  IdentityPicture = "IDENTITY_PICTURE",
}

export type BackgammonAnswerOnDoubleBetInput = {
  tableId: Scalars["ID"];
  answer: DoubleProposalAnswer;
};

export type BackgammonAnswerOnDoubleBetWithCubeInput = {
  tableId: Scalars["ID"];
  accepted: Scalars["Boolean"];
};

export type BackgammonAnswerOnRedoubleBetInput = {
  tableId: Scalars["ID"];
  answer: Maybe<DoubleProposalAnswer>;
};

export type BackgammonDefaultConfig = {
  bet: BackgammonDefaultConfigTuple;
  turnTime: BackgammonDefaultConfigTuple;
  points: BackgammonDefaultConfigTuple;
  commissions: Array<CommissionConfig>;
  usingDoublingCube: Scalars["Boolean"];
};

export type BackgammonDefaultConfigTuple = {
  minimum: Scalars["Int"];
  maximum: Scalars["Int"];
  default: Scalars["Int"];
  step: Scalars["Int"];
};

export type BackgammonDoubleBetInput = {
  tableId: Scalars["ID"];
};

export type BackgammonDoubleBetWithCubeInput = {
  tableId: Scalars["ID"];
};

export type BackgammonInvitePlayerInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type BackgammonMoveInput = {
  tableId: Scalars["ID"];
  type: MoveType;
  from: Maybe<Scalars["Int"]>;
  to: Maybe<Scalars["Int"]>;
};

export type BackgammonOpeningRollInput = {
  tableId: Scalars["ID"];
};

export type BackgammonRollDicesInput = {
  tableId: Scalars["ID"];
};

export type BackgammonSetReadyInput = {
  tableId: Scalars["ID"];
};

export type BackgammonTableInput = {
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: BackgammonTableType;
  turnTime: Scalars["Int"];
  bet: Scalars["Int"];
  maxBet: Maybe<Scalars["Int"]>;
};

export enum BackgammonTableType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type BalanceUpdateAdjustmentActivityContent = {
  amounts: Array<Amount>;
};

export type BalanceUpdateAdjustmentDetails = {
  notes: Scalars["String"];
};

export type BalanceUpdateAvatarPurchaseDetails = {
  avatarId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateHintDetails = {
  numberOfHints: Scalars["Int"];
};

export type BalanceUpdateLeaderBoardAwardContent = {
  position: Scalars["Int"];
  leaderBoard: Leaderboard;
};

export type BalanceUpdateLeaderBoardAwardDetails = {
  leaderboardId: Scalars["String"];
  position: Scalars["Int"];
};

export type BalanceUpdatePurchaseDetails = {
  name: Scalars["String"];
  chipPackId: Scalars["String"];
};

export type BalanceUpdateReceivedFromUserDetails = {
  userId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateReferDetails = {
  userId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateReferredByDetails = {
  userId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateRewardedAdDetails = {
  rewardId: Scalars["String"];
};

export type BalanceUpdateRollbackDetails = {
  created: Scalars["DateTime"];
};

export type BalanceUpdateScratchCardPurchaseDetails = {
  scratchCardId: Scalars["String"];
  name: Scalars["String"];
  displayId: Scalars["String"];
};

export type BalanceUpdateScratchCardWinDetails = {
  scratchCardId: Scalars["String"];
  name: Scalars["String"];
  displayId: Scalars["String"];
};

export type BalanceUpdateSendActivityContent = {
  amounts: Array<Amount>;
};

export type BalanceUpdateSendToUserDetails = {
  userId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateSystemGiftDetails = {
  name: Scalars["String"];
};

export type BalanceUpdateTableDetails = {
  tableId: Scalars["String"];
  displayId: Scalars["String"];
  game: Scalars["String"];
  action: BalanceUpdateTableDetailsAction;
};

export enum BalanceUpdateTableDetailsAction {
  Start = "START",
  Complete = "COMPLETE",
  Cancel = "CANCEL",
  Double = "DOUBLE",
  Redouble = "REDOUBLE",
}

export type BalanceUpdateTopupDetails = {
  topupName: Scalars["String"];
  topupCardId: Scalars["String"];
};

export type BalanceUpdateTournamentAwardContent = {
  position: Scalars["Int"];
  tournament: Tournament;
};

export type BalanceUpdateTournamentAwardDetails = {
  tournamentId: Scalars["String"];
  position: Scalars["Int"];
};

export type BalanceUpdateTournamentPurchaseDetails = {
  tournamentId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateVirtualItemGiftDetails = {
  tableId: Scalars["String"];
  displayId: Scalars["String"];
  itemId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateVirtualItemGiftInChatDetails = {
  chatId: Scalars["String"];
  itemId: Scalars["String"];
  name: Scalars["String"];
};

export type BalanceUpdateWelcomeDetails = {
  created: Scalars["DateTime"];
};

export type BalancesUpdate = UserUpdate & {
  userId: Scalars["ID"];
  balances: Array<Amount>;
};

export type Ban = {
  from: Player;
  to: Player;
  created: Scalars["DateTime"];
};

export type BannedUser = {
  userA: Player;
  userB: Player;
  created: Scalars["DateTime"];
};

export type BannedUsers = {
  edges: Array<BannedUsersEdge>;
  pageInfo: CursorPageInfo;
};

export type BannedUsersEdge = {
  node: BannedUser;
};

export type BanningType = {
  canAskForBanning: Scalars["Boolean"];
  areBanned: Scalars["Boolean"];
};

export type BeloteAcceptTrumpInput = {
  tableId: Scalars["ID"];
};

export type BeloteCoincheAnswerOnDoubleBetInput = {
  tableId: Scalars["ID"];
  answer: DoubleProposalAnswer;
};

export type BeloteCoincheAnswerOnRedoubleBetInput = {
  tableId: Scalars["ID"];
  answer: Maybe<DoubleProposalAnswer>;
};

export type BeloteCoincheBetByPointsConfig = {
  maxPoints: Scalars["Int"];
  minBet: Scalars["Int"];
};

export type BeloteCoincheChangeSeatInput = {
  tableId: Scalars["ID"];
  seat: Scalars["Int"];
};

export type BeloteCoincheDeclareBeloteInput = {
  tableId: Scalars["ID"];
};

export type BeloteCoincheDeclareInput = {
  tableId: Scalars["ID"];
};

export type BeloteCoincheDefaultConfig = {
  bet: BeloteCoincheDefaultConfigTuple;
  turnTime: BeloteCoincheDefaultConfigTuple;
  points: BeloteCoincheDefaultConfigTuple;
  betByPoints: Array<BeloteCoincheBetByPointsConfig>;
  commissions: Array<CommissionConfig>;
};

export type BeloteCoincheDefaultConfigTuple = {
  minimum: Scalars["Int"];
  maximum: Scalars["Int"];
  default: Scalars["Int"];
  step: Scalars["Int"];
};

export type BeloteCoincheDoubleBetInput = {
  tableId: Scalars["ID"];
};

export type BeloteCoincheInvitePlayerInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type BeloteCoincheNextRoundInput = {
  tableId: Scalars["ID"];
};

export type BeloteCoinchePlayInput = {
  tableId: Scalars["ID"];
  card: Scalars["String"];
};

export type BeloteCoincheSetReadyInput = {
  tableId: Scalars["ID"];
};

export enum BeloteCoincheTableType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type BelotePassInput = {
  tableId: Scalars["ID"];
};

export enum BelotePlayers {
  OneVsOne = "ONE_VS_ONE",
  TwoVsTwo = "TWO_VS_TWO",
}

export type BeloteSelectTrumpInput = {
  tableId: Scalars["ID"];
  trump: Suit;
};

export type BeloteTableInput = {
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: BeloteCoincheTableType;
  bet: Scalars["Int"];
  turnTime: Scalars["Int"];
  points: Scalars["Int"];
  players: BelotePlayers;
  avoidPutTrumpOption: Scalars["Boolean"];
};

export type CanSendChipsLimit = {
  numberOfGames: Maybe<Scalars["Int"]>;
  maximumChips: Maybe<Scalars["Int"]>;
};

export type ChallengeDailyStatistic = {
  created: Scalars["DateTime"];
  roundsWin: Scalars["Int"];
  elapsedTimeMs: Scalars["Int"];
};

export type ChatConfigUpdate = UserUpdate & {
  userId: Scalars["ID"];
  isChatEnabled: Scalars["Boolean"];
};

export type ChatMessage = {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  player: Player;
  message: Scalars["String"];
};

export enum ChatTheme {
  Contrast = "CONTRAST",
  Dark = "DARK",
  Light = "LIGHT",
}

export type ChatUserListInput = {
  name: Scalars["String"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type ChatUsers = {
  ids: Array<Scalars["ID"]>;
  hasNextPage: Scalars["Boolean"];
};

export type ChipPack = {
  id: Scalars["ID"];
  name: Scalars["String"];
  primary: Amount;
  secondary: Maybe<Amount>;
  sell: Amount;
  path: Scalars["String"];
  vipDays: Scalars["Int"];
};

export type CoincheMakeDeclarationsInput = {
  tableId: Scalars["ID"];
};

export type CoincheMakeStatementInput = {
  tableId: Scalars["ID"];
  trump: Maybe<Suit>;
  points: Maybe<Scalars["Int"]>;
  statement: Statement;
};

export type CoincheTableInput = {
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: BeloteCoincheTableType;
  bet: Scalars["Int"];
  turnTime: Scalars["Int"];
  points: Scalars["Int"];
  avoidPutTrumpOption: Scalars["Boolean"];
};

export type CommissionConfig = {
  maxBet: Scalars["Int"];
  commission: Scalars["Float"];
};

export type CompleteSteamPurchaseInput = {
  purchaseId: Scalars["ID"];
  tenant: Scalars["String"];
};

export type ConsecutiveActivityRewardsConfigDetails = {
  userId: Scalars["String"];
};

export type CreateFacebookInstantGamePurchaseInput = {
  signedRequest: Scalars["String"];
  tenant: Scalars["String"];
};

export enum Currency {
  BlueChips = "BLUE_CHIPS",
  GoldChips = "GOLD_CHIPS",
  RewardPoints = "REWARD_POINTS",
  RealMoney = "REAL_MONEY",
}

export type CursorPageInfo = {
  endCursor: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
};

export type DailyActivityReward = {
  blueChips: Scalars["Float"];
  goldChips: Scalars["Float"];
  rewardPoints: Scalars["Float"];
};

export type DailyActivityRewardItem = {
  dayNumber: Scalars["Int"];
  config: DailyActivityReward;
  paid: Maybe<DailyActivityReward>;
};

export type Dimensions = {
  width: Scalars["Int"];
  height: Scalars["Int"];
};

export enum DoubleProposalAnswer {
  Confirm = "CONFIRM",
  Refuse = "REFUSE",
  Redouble = "REDOUBLE",
}

export enum DoubleProposalState {
  NotAllowed = "NOT_ALLOWED",
  None = "NONE",
  Doubling = "DOUBLING",
  DoubleConfirmed = "DOUBLE_CONFIRMED",
  Redoubling = "REDOUBLING",
  RedoubleConfirmed = "REDOUBLE_CONFIRMED",
}

export type EcommpayOrderInput = {
  chipPackId: Scalars["ID"];
  locale: Scalars["String"];
};

export type FriendRequest = {
  from: Player;
  to: Player;
  created: Scalars["DateTime"];
};

export type FriendRequestAcceptedActivityContent = {
  established: Scalars["DateTime"];
};

export type FriendRequestActivityContent = {
  initialized: Scalars["DateTime"];
};

export type FriendShip = {
  userA: Player;
  userB: Player;
  established: Scalars["DateTime"];
};

export type FriendShipEdge = {
  node: FriendShip;
};

export type Friends = {
  edges: Array<FriendShipEdge>;
  pageInfo: CursorPageInfo;
};

export type FriendshipCanceledActivityContent = {
  canceled: Scalars["DateTime"];
};

export type FriendshipType = {
  canAskForFriendship: Scalars["Boolean"];
  areFriends: Scalars["Boolean"];
};

export type GameSection = Section & {
  id: Scalars["ID"];
  count: Scalars["Int"];
  type: Scalars["String"];
  tables: Tables;
  leaderboards: Array<Leaderboard>;
  googlePlayLink: Maybe<Scalars["String"]>;
  appStoreLink: Maybe<Scalars["String"]>;
  logo: Maybe<Scalars["String"]>;
};

export type GameSectionTablesArgs = {
  after: Scalars["String"];
};

export type GameStatistics = {
  game: Scalars["String"];
  win: Scalars["Int"];
  lose: Scalars["Int"];
  draw: Scalars["Int"];
  quit: Scalars["Int"];
  ranking: Scalars["Int"];
};

export type GenericPlayer = {
  id: Scalars["ID"];
  name: Scalars["String"];
  presence: Scalars["Boolean"];
  country: Maybe<Scalars["String"]>;
  balances: Array<Amount>;
  statistics: PlayerStatistics;
  activeTables: Array<Table>;
};

export type GroupChatImageInput = {
  chatId: Scalars["ID"];
  file: Scalars["Upload"];
};

export type HangmanBetByNumberOfRoundsConfig = {
  maxRounds: Scalars["Int"];
  minBet: Scalars["Int"];
};

export type HangmanChallenge = {
  available: Scalars["Boolean"];
};

export type HangmanChallengeLeaderboardRecord = LeaderboardRecord & {
  id: Scalars["ID"];
  player: Player;
  position: Scalars["Int"];
  leaderboard: Leaderboard;
  language: Scalars["String"];
  roundsWin: Scalars["Int"];
  elapsedTimeMs: Scalars["Int"];
};

export type HangmanChallengesInput = {
  roomId: Scalars["ID"];
  month: Scalars["Int"];
  year: Scalars["Int"];
};

export type HangmanClickHintInput = {
  tableId: Scalars["ID"];
};

export type HangmanDefaultConfig = {
  singlePlayer: HangmanSinglePlayer;
  multiPlayer: HangmanMultiPlayer;
  challenge: HangmanChallenge;
  settings: Array<HangmanSettings>;
};

export type HangmanDefaultConfigTuple = {
  minimum: Scalars["Int"];
  maximum: Scalars["Int"];
  default: Scalars["Int"];
  step: Scalars["Int"];
};

export enum HangmanGameMode {
  SinglePlayer = "SINGLE_PLAYER",
  MultiPlayer = "MULTI_PLAYER",
  Challenge = "CHALLENGE",
}

export type HangmanInvitePlayerInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type HangmanMakeMoveInput = {
  tableId: Scalars["ID"];
  letter: Scalars["String"];
};

export type HangmanMultiPlayer = {
  bet: HangmanDefaultConfigTuple;
  maxNumberOfPlayers: Scalars["Int"];
  numberOfRounds: HangmanDefaultConfigTuple;
  levelOfDifficulty: HangmanDefaultConfigTuple;
  commissions: Array<CommissionConfig>;
  betByNumberOfRounds: Array<HangmanBetByNumberOfRoundsConfig>;
};

export type HangmanNextRoundInput = {
  tableId: Scalars["ID"];
};

export type HangmanSetReadyInput = {
  tableId: Scalars["ID"];
};

export type HangmanSettings = {
  language: Scalars["String"];
  levels: Array<Scalars["Int"]>;
};

export type HangmanSinglePlayer = {
  bet: HangmanDefaultConfigTuple;
  levelOfDifficulty: HangmanDefaultConfigTuple;
  commissions: Array<CommissionConfig>;
};

export type HangmanTableInput = {
  mode: HangmanGameMode;
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: HangmanTableType;
  numberOfRounds: Maybe<Scalars["Int"]>;
  numberOfPlayers: Maybe<Scalars["Int"]>;
  bet: Maybe<Scalars["Int"]>;
  levelOfDifficulty: Maybe<Scalars["Int"]>;
  language: Scalars["String"];
};

export enum HangmanTableType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type Leaderboard = {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  section: Section;
  name: Scalars["String"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
  active: Scalars["Boolean"];
  initialRanking: Scalars["Int"];
  qualifyGames: Scalars["Int"];
  topGames: Maybe<Scalars["Int"]>;
  records: Array<LeaderboardRecord>;
  prices: Array<LeaderboardPrice>;
  me: Maybe<LeaderboardRecord>;
  logo: Scalars["String"];
};

export type LeaderboardRecordsArgs = {
  limit: Scalars["Int"];
};

export type LeaderboardPrice = {
  from: Scalars["Int"];
  to: Scalars["Int"];
  amount: Amount;
};

export type LeaderboardRecord = {
  id: Scalars["ID"];
  player: Player;
  position: Scalars["Int"];
  leaderboard: Leaderboard;
};

export type LeaderboardsFilter = {
  rooms: Array<Scalars["ID"]>;
  sections: Array<Scalars["ID"]>;
};

export type LimitOffsetPageInfo = {
  currentPage: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type MainScreenItem = {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  settingCategory: SettingCategory;
  settingValue: MainScreenItemSettingValue;
  removed: Maybe<Scalars["DateTime"]>;
};

export type MainScreenItemSettingValue = {
  desktopImage: Scalars["String"];
  tabletImage: Scalars["String"];
  mobileImage: Scalars["String"];
  link: Scalars["String"];
};

export type Marquee = {
  id: Scalars["ID"];
  link: Maybe<Scalars["String"]>;
  content: Scalars["String"];
  duration: Scalars["Int"];
  delay: Scalars["Int"];
};

export enum MoveType {
  MoveChecker = "MOVE_CHECKER",
  EnterChecker = "ENTER_CHECKER",
  BearOffChecker = "BEAR_OFF_CHECKER",
  SkipTurn = "SKIP_TURN",
}

export type MpirimpaAnswerOnDoubleBetInput = {
  tableId: Scalars["ID"];
  answer: DoubleProposalAnswer;
};

export type MpirimpaAnswerOnRedoubleBetInput = {
  tableId: Scalars["ID"];
  answer: Maybe<DoubleProposalAnswer>;
};

export type MpirimpaBetByPointsConfig = {
  maxPoints: Scalars["Int"];
  minBet: Scalars["Int"];
};

export type MpirimpaChangeSeatInput = {
  tableId: Scalars["ID"];
  seat: Scalars["Int"];
};

export type MpirimpaCollectDiscardPileInput = {
  tableId: Scalars["ID"];
};

export type MpirimpaDefaultConfig = {
  bet: MpirimpaDefaultConfigTuple;
  turnTime: MpirimpaDefaultConfigTuple;
  points: MpirimpaDefaultConfigTuple;
  betByPoints: Array<MpirimpaBetByPointsConfig>;
  groupCardSets: Maybe<Scalars["Boolean"]>;
  commissions: Array<CommissionConfig>;
};

export type MpirimpaDefaultConfigTuple = {
  minimum: Scalars["Int"];
  maximum: Scalars["Int"];
  default: Scalars["Int"];
  step: Scalars["Int"];
};

export type MpirimpaDoubleBetInput = {
  tableId: Scalars["ID"];
};

export type MpirimpaDropCardToDiscardPileInput = {
  tableId: Scalars["ID"];
  card: Scalars["String"];
};

export type MpirimpaDropCardToSetInput = {
  tableId: Scalars["ID"];
  cards: Array<Scalars["String"]>;
  setIndex: Maybe<Scalars["Int"]>;
};

export type MpirimpaEndTurnInput = {
  tableId: Scalars["ID"];
};

export type MpirimpaInvitePlayerInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type MpirimpaNextRoundInput = {
  tableId: Scalars["ID"];
};

export enum MpirimpaPlayers {
  OneVsOne = "ONE_VS_ONE",
  TwoVsTwo = "TWO_VS_TWO",
}

export type MpirimpaSetReadyInput = {
  tableId: Scalars["ID"];
};

export type MpirimpaTableInput = {
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: MpirimpaTableType;
  turnTime: Scalars["Int"];
  bet: Scalars["Int"];
  points: Scalars["Int"];
  players: MpirimpaPlayers;
  groupCardSets: Scalars["Boolean"];
};

export enum MpirimpaTableType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type MpirimpaTakeCardFromDeckInput = {
  tableId: Scalars["ID"];
};

export type Mutation = {
  sendBalance: Maybe<Array<SendBalanceResponse>>;
  sendMessage: Maybe<ChatMessage>;
  cancelInvitation: Maybe<Scalars["Boolean"]>;
  useAvatar: Maybe<Scalars["Boolean"]>;
  deleteMe: Maybe<Scalars["ID"]>;
  userToogleAgreementGetEmail: Maybe<Scalars["Boolean"]>;
  upsertAcceptDirectMessages: Maybe<Scalars["Boolean"]>;
  upsertChatTheme: Maybe<ChatTheme>;
  renameUser: Maybe<User>;
  watchedAds: Maybe<AdsResponse>;
  updateFirebaseId: Maybe<User>;
  userGameConfigurationLanguage: Maybe<Scalars["Boolean"]>;
  sendRoomChatMessage: Maybe<ChatMessage>;
  createFacebookOrder: Maybe<PendingFacebookOrder>;
  completeFacebookOrder: Maybe<Purchase>;
  createDirectPurchase: Maybe<Purchase>;
  createEcommpayOrder: Maybe<Scalars["JSON"]>;
  createUnityPurchase: Maybe<Purchase>;
  createVivaOrder: Maybe<Scalars["JSON"]>;
  createFacebookInstantGamePurchase: Maybe<Purchase>;
  initSteamPurchase: Maybe<Scalars["JSON"]>;
  completeSteamPurchase: Maybe<Purchase>;
  createFriendRequest: Maybe<FriendRequest>;
  cancelFriendRequest: Maybe<FriendRequest>;
  answerFriendRequest: Maybe<FriendShip>;
  cancelFriendship: Maybe<FriendShip>;
  createBan: Maybe<Ban>;
  cancelBan: Maybe<Ban>;
  translate: Maybe<Scalars["String"]>;
  redeemSystemGift: Maybe<Array<Amount>>;
  purchaseEntry: Maybe<TournamentRecord>;
  purchaseScratchCard: Maybe<PurchasedScratchCard>;
  redeemScratchCard: Maybe<PurchasedScratchCard>;
  redeemTopup: Maybe<Array<Amount>>;
  upsertProfilePhoto: Maybe<ProfilePhoto>;
  uploadGroupChatImage: Maybe<Scalars["ID"]>;
  purchaseVirtualItemInChat: Maybe<VirtualItemInChatPurchase>;
  ticTacToeCreateOpenTable: Maybe<Table>;
  ticTacToeCreateTournamentTable: Maybe<Table>;
  ticTacToeMakeMove: Maybe<Scalars["Int"]>;
  ticTacToeSetReady: Maybe<Scalars["Int"]>;
  ticTacToeInvitePlayer: Maybe<Scalars["Int"]>;
  ticTacToeNextRound: Maybe<Scalars["Int"]>;
  ticTacToeDoubleBet: Maybe<Scalars["Int"]>;
  ticTacToeAnswerOnDoubleBet: Maybe<Scalars["Int"]>;
  ticTacToeAnswerOnRedoubleBet: Maybe<Scalars["Int"]>;
  sendTableChatMessage: Maybe<ChatMessage>;
  purchaseVirtualItem: Maybe<VirtualItemPurchasesMessage>;
  hangmanCreateOpenTable: Maybe<Table>;
  hangmanCreateTournamentTable: Maybe<Table>;
  hangmanMakeMove: Maybe<Scalars["Int"]>;
  hangmanClickHint: Maybe<Scalars["Int"]>;
  hangmanSetReady: Maybe<Scalars["Int"]>;
  hangmanInvitePlayer: Maybe<Scalars["Int"]>;
  hangmanNextRound: Maybe<Scalars["Int"]>;
  mpirimpaCreateOpenTable: Maybe<Table>;
  mpirimpaCreateTournamentTable: Maybe<Table>;
  mpirimpaCollectDiscardPile: Maybe<Scalars["Int"]>;
  mpirimpaTakeCardFromDeck: Maybe<Scalars["Int"]>;
  mpirimpaDropCardToDiscardPile: Maybe<Scalars["Int"]>;
  mpirimpaDropCardsToSet: Maybe<Scalars["Int"]>;
  mpirimpaSetReady: Maybe<Scalars["Int"]>;
  mpirimpaEndTurn: Maybe<Scalars["Int"]>;
  mpirimpaNextRound: Maybe<Scalars["Int"]>;
  mpirimpaInvitePlayer: Maybe<Scalars["Int"]>;
  mpirimpaChangeSeat: Maybe<Scalars["Int"]>;
  mpirimpaDoubleBet: Maybe<Scalars["Int"]>;
  mpirimpaAnswerOnDoubleBet: Maybe<Scalars["Int"]>;
  mpirimpaAnswerOnRedoubleBet: Maybe<Scalars["Int"]>;
  tichuCreateOpenTable: Maybe<Table>;
  tichuCreateTournamentTable: Maybe<Table>;
  tichuSetReady: Maybe<Scalars["Int"]>;
  tichuInvitePlayer: Maybe<Scalars["Int"]>;
  tichuNextRound: Maybe<Scalars["Int"]>;
  tichuDeclare: Maybe<Scalars["Int"]>;
  tichuChangeSeat: Maybe<Scalars["Int"]>;
  tichuPass: Maybe<Scalars["Int"]>;
  tichuPrepareBomb: Maybe<Scalars["Int"]>;
  tichuPassDragon: Maybe<Scalars["Int"]>;
  tichuSendCards: Maybe<Scalars["Int"]>;
  tichuPlay: Maybe<Scalars["Int"]>;
  tichuDoubleBet: Maybe<Scalars["Int"]>;
  tichuAnswerOnDoubleBet: Maybe<Scalars["Int"]>;
  tichuAnswerOnRedoubleBet: Maybe<Scalars["Int"]>;
  beloteCreateOpenTable: Maybe<Table>;
  beloteAcceptTrump: Maybe<Scalars["Int"]>;
  beloteSelectTrump: Maybe<Scalars["Int"]>;
  belotePass: Maybe<Scalars["Int"]>;
  beloteCoincheCreateTournamentTable: Maybe<Table>;
  beloteCoincheSetReady: Maybe<Scalars["Int"]>;
  beloteCoincheInvitePlayer: Maybe<Scalars["Int"]>;
  beloteCoincheNextRound: Maybe<Scalars["Int"]>;
  beloteCoincheDeclare: Maybe<Scalars["Int"]>;
  beloteCoincheChangeSeat: Maybe<Scalars["Int"]>;
  beloteCoinchePlay: Maybe<Scalars["Int"]>;
  beloteCoincheDeclareBelote: Maybe<Scalars["Int"]>;
  beloteCoincheDoubleBet: Maybe<Scalars["Int"]>;
  beloteCoincheAnswerOnDoubleBet: Maybe<Scalars["Int"]>;
  beloteCoincheAnswerOnRedoubleBet: Maybe<Scalars["Int"]>;
  coincheCreateOpenTable: Maybe<Table>;
  coincheMakeStatement: Maybe<Scalars["Int"]>;
  coincheMakeDeclarations: Maybe<Scalars["Int"]>;
  backgammonCreateOpenTable: Maybe<Table>;
  backgammonCreateTournamentTable: Maybe<Table>;
  backgammonSetReady: Maybe<Scalars["Int"]>;
  backgammonInvitePlayer: Maybe<Scalars["Int"]>;
  backgammonDoubleBet: Maybe<Scalars["Int"]>;
  backgammonAnswerOnDoubleBet: Maybe<Scalars["Int"]>;
  backgammonAnswerOnRedoubleBet: Maybe<Scalars["Int"]>;
  backgammonOpeningRoll: Maybe<Scalars["Int"]>;
  backgammonRollDices: Maybe<Scalars["Int"]>;
  backgammonMove: Maybe<Scalars["Int"]>;
  backgammonDoubleBetWithCube: Maybe<Scalars["Int"]>;
  backgammonAnswerOnDoubleBetWithCube: Maybe<Scalars["Int"]>;
};

export type MutationSendBalanceArgs = {
  input: SendBalanceInput;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationCancelInvitationArgs = {
  id: Scalars["ID"];
};

export type MutationUseAvatarArgs = {
  input: UseAvatarInput;
};

export type MutationUserToogleAgreementGetEmailArgs = {
  agreement: Scalars["Boolean"];
};

export type MutationUpsertAcceptDirectMessagesArgs = {
  acceptDirectMessages: Scalars["Boolean"];
};

export type MutationUpsertChatThemeArgs = {
  chatTheme: ChatTheme;
};

export type MutationRenameUserArgs = {
  newName: Scalars["String"];
};

export type MutationWatchedAdsArgs = {
  count: Scalars["Int"];
};

export type MutationUpdateFirebaseIdArgs = {
  firebaseId: Scalars["String"];
};

export type MutationUserGameConfigurationLanguageArgs = {
  language: Scalars["String"];
  portal: Scalars["String"];
  tenant: Scalars["String"];
};

export type MutationSendRoomChatMessageArgs = {
  input: RoomChatMessageInput;
};

export type MutationCreateFacebookOrderArgs = {
  input: Scalars["ID"];
};

export type MutationCompleteFacebookOrderArgs = {
  input: Scalars["String"];
};

export type MutationCreateDirectPurchaseArgs = {
  input: Scalars["ID"];
};

export type MutationCreateEcommpayOrderArgs = {
  input: EcommpayOrderInput;
};

export type MutationCreateUnityPurchaseArgs = {
  input: Scalars["String"];
};

export type MutationCreateVivaOrderArgs = {
  input: VivaOrderInput;
};

export type MutationCreateFacebookInstantGamePurchaseArgs = {
  input: CreateFacebookInstantGamePurchaseInput;
};

export type MutationInitSteamPurchaseArgs = {
  input: SteamInitPurchaseInput;
};

export type MutationCompleteSteamPurchaseArgs = {
  input: CompleteSteamPurchaseInput;
};

export type MutationCreateFriendRequestArgs = {
  withUser: Scalars["ID"];
};

export type MutationCancelFriendRequestArgs = {
  toUser: Scalars["ID"];
};

export type MutationAnswerFriendRequestArgs = {
  fromUser: Scalars["ID"];
  answer: Scalars["Boolean"];
};

export type MutationCancelFriendshipArgs = {
  withUser: Scalars["ID"];
};

export type MutationCreateBanArgs = {
  withUser: Scalars["ID"];
};

export type MutationCancelBanArgs = {
  toUser: Scalars["ID"];
};

export type MutationTranslateArgs = {
  input: TranslationInput;
};

export type MutationRedeemSystemGiftArgs = {
  gift: Scalars["ID"];
};

export type MutationPurchaseEntryArgs = {
  id: Scalars["ID"];
};

export type MutationPurchaseScratchCardArgs = {
  id: Scalars["ID"];
};

export type MutationRedeemScratchCardArgs = {
  id: Scalars["ID"];
};

export type MutationRedeemTopupArgs = {
  topup: Scalars["ID"];
};

export type MutationUpsertProfilePhotoArgs = {
  input: ProfilePhotoInput;
};

export type MutationUploadGroupChatImageArgs = {
  input: GroupChatImageInput;
};

export type MutationPurchaseVirtualItemInChatArgs = {
  input: Maybe<VirtualItemInChatPurchaseInput>;
};

export type MutationTicTacToeCreateOpenTableArgs = {
  input: TicTacToeTableInput;
};

export type MutationTicTacToeCreateTournamentTableArgs = {
  sectionId: Scalars["ID"];
};

export type MutationTicTacToeMakeMoveArgs = {
  input: TicTacToeMakeMoveInput;
};

export type MutationTicTacToeSetReadyArgs = {
  input: TicTacToeSetReadyInput;
};

export type MutationTicTacToeInvitePlayerArgs = {
  input: TicTacToeInvitePlayerInput;
};

export type MutationTicTacToeNextRoundArgs = {
  input: TicTacToeNextRoundInput;
};

export type MutationTicTacToeDoubleBetArgs = {
  input: TicTacToeDoubleBetInput;
};

export type MutationTicTacToeAnswerOnDoubleBetArgs = {
  input: Maybe<TicTacToeAnswerOnDoubleBetInput>;
};

export type MutationTicTacToeAnswerOnRedoubleBetArgs = {
  input: Maybe<TicTacToeAnswerOnRedoubleBetInput>;
};

export type MutationSendTableChatMessageArgs = {
  input: TableChatMessageInput;
};

export type MutationPurchaseVirtualItemArgs = {
  input: Maybe<VirtualItemPurchaseInput>;
};

export type MutationHangmanCreateOpenTableArgs = {
  input: HangmanTableInput;
};

export type MutationHangmanCreateTournamentTableArgs = {
  sectionId: Scalars["ID"];
};

export type MutationHangmanMakeMoveArgs = {
  input: HangmanMakeMoveInput;
};

export type MutationHangmanClickHintArgs = {
  input: HangmanClickHintInput;
};

export type MutationHangmanSetReadyArgs = {
  input: HangmanSetReadyInput;
};

export type MutationHangmanInvitePlayerArgs = {
  input: HangmanInvitePlayerInput;
};

export type MutationHangmanNextRoundArgs = {
  input: HangmanNextRoundInput;
};

export type MutationMpirimpaCreateOpenTableArgs = {
  input: MpirimpaTableInput;
};

export type MutationMpirimpaCreateTournamentTableArgs = {
  sectionId: Scalars["ID"];
};

export type MutationMpirimpaCollectDiscardPileArgs = {
  input: Maybe<MpirimpaCollectDiscardPileInput>;
};

export type MutationMpirimpaTakeCardFromDeckArgs = {
  input: Maybe<MpirimpaTakeCardFromDeckInput>;
};

export type MutationMpirimpaDropCardToDiscardPileArgs = {
  input: Maybe<MpirimpaDropCardToDiscardPileInput>;
};

export type MutationMpirimpaDropCardsToSetArgs = {
  input: Maybe<MpirimpaDropCardToSetInput>;
};

export type MutationMpirimpaSetReadyArgs = {
  input: MpirimpaSetReadyInput;
};

export type MutationMpirimpaEndTurnArgs = {
  input: Maybe<MpirimpaEndTurnInput>;
};

export type MutationMpirimpaNextRoundArgs = {
  input: MpirimpaNextRoundInput;
};

export type MutationMpirimpaInvitePlayerArgs = {
  input: MpirimpaInvitePlayerInput;
};

export type MutationMpirimpaChangeSeatArgs = {
  input: MpirimpaChangeSeatInput;
};

export type MutationMpirimpaDoubleBetArgs = {
  input: MpirimpaDoubleBetInput;
};

export type MutationMpirimpaAnswerOnDoubleBetArgs = {
  input: Maybe<MpirimpaAnswerOnDoubleBetInput>;
};

export type MutationMpirimpaAnswerOnRedoubleBetArgs = {
  input: Maybe<MpirimpaAnswerOnRedoubleBetInput>;
};

export type MutationTichuCreateOpenTableArgs = {
  input: TichuTableInput;
};

export type MutationTichuCreateTournamentTableArgs = {
  sectionId: Scalars["ID"];
};

export type MutationTichuSetReadyArgs = {
  input: TichuSetReadyInput;
};

export type MutationTichuInvitePlayerArgs = {
  input: TichuInvitePlayerInput;
};

export type MutationTichuNextRoundArgs = {
  input: TichuNextRoundInput;
};

export type MutationTichuDeclareArgs = {
  input: TichuDeclareInput;
};

export type MutationTichuChangeSeatArgs = {
  input: TichuChangeSeatInput;
};

export type MutationTichuPassArgs = {
  input: TichuPassInput;
};

export type MutationTichuPrepareBombArgs = {
  input: TichuPrepareBombInput;
};

export type MutationTichuPassDragonArgs = {
  input: TichuPassDragonInput;
};

export type MutationTichuSendCardsArgs = {
  input: TichuSendCardsInput;
};

export type MutationTichuPlayArgs = {
  input: TichuPlayInput;
};

export type MutationTichuDoubleBetArgs = {
  input: TichuDoubleBetInput;
};

export type MutationTichuAnswerOnDoubleBetArgs = {
  input: Maybe<TichuAnswerOnDoubleBetInput>;
};

export type MutationTichuAnswerOnRedoubleBetArgs = {
  input: Maybe<TichuAnswerOnRedoubleBetInput>;
};

export type MutationBeloteCreateOpenTableArgs = {
  input: BeloteTableInput;
};

export type MutationBeloteAcceptTrumpArgs = {
  input: BeloteAcceptTrumpInput;
};

export type MutationBeloteSelectTrumpArgs = {
  input: BeloteSelectTrumpInput;
};

export type MutationBelotePassArgs = {
  input: BelotePassInput;
};

export type MutationBeloteCoincheCreateTournamentTableArgs = {
  sectionId: Scalars["ID"];
};

export type MutationBeloteCoincheSetReadyArgs = {
  input: BeloteCoincheSetReadyInput;
};

export type MutationBeloteCoincheInvitePlayerArgs = {
  input: BeloteCoincheInvitePlayerInput;
};

export type MutationBeloteCoincheNextRoundArgs = {
  input: BeloteCoincheNextRoundInput;
};

export type MutationBeloteCoincheDeclareArgs = {
  input: BeloteCoincheDeclareInput;
};

export type MutationBeloteCoincheChangeSeatArgs = {
  input: BeloteCoincheChangeSeatInput;
};

export type MutationBeloteCoinchePlayArgs = {
  input: BeloteCoinchePlayInput;
};

export type MutationBeloteCoincheDeclareBeloteArgs = {
  input: BeloteCoincheDeclareBeloteInput;
};

export type MutationBeloteCoincheDoubleBetArgs = {
  input: BeloteCoincheDoubleBetInput;
};

export type MutationBeloteCoincheAnswerOnDoubleBetArgs = {
  input: Maybe<BeloteCoincheAnswerOnDoubleBetInput>;
};

export type MutationBeloteCoincheAnswerOnRedoubleBetArgs = {
  input: Maybe<BeloteCoincheAnswerOnRedoubleBetInput>;
};

export type MutationCoincheCreateOpenTableArgs = {
  input: CoincheTableInput;
};

export type MutationCoincheMakeStatementArgs = {
  input: CoincheMakeStatementInput;
};

export type MutationCoincheMakeDeclarationsArgs = {
  input: CoincheMakeDeclarationsInput;
};

export type MutationBackgammonCreateOpenTableArgs = {
  input: BackgammonTableInput;
};

export type MutationBackgammonCreateTournamentTableArgs = {
  sectionId: Scalars["ID"];
};

export type MutationBackgammonSetReadyArgs = {
  input: BackgammonSetReadyInput;
};

export type MutationBackgammonInvitePlayerArgs = {
  input: BackgammonInvitePlayerInput;
};

export type MutationBackgammonDoubleBetArgs = {
  input: BackgammonDoubleBetInput;
};

export type MutationBackgammonAnswerOnDoubleBetArgs = {
  input: Maybe<BackgammonAnswerOnDoubleBetInput>;
};

export type MutationBackgammonAnswerOnRedoubleBetArgs = {
  input: Maybe<BackgammonAnswerOnRedoubleBetInput>;
};

export type MutationBackgammonOpeningRollArgs = {
  input: Maybe<BackgammonOpeningRollInput>;
};

export type MutationBackgammonRollDicesArgs = {
  input: Maybe<BackgammonRollDicesInput>;
};

export type MutationBackgammonMoveArgs = {
  input: BackgammonMoveInput;
};

export type MutationBackgammonDoubleBetWithCubeArgs = {
  input: Maybe<BackgammonDoubleBetWithCubeInput>;
};

export type MutationBackgammonAnswerOnDoubleBetWithCubeArgs = {
  input: Maybe<BackgammonAnswerOnDoubleBetWithCubeInput>;
};

export type NextFreeScratchCardUpdate = UserUpdate & {
  userId: Scalars["ID"];
  nextFreeScratchCard: Maybe<Scalars["DateTime"]>;
};

export type OpenLeaderboard = Leaderboard & {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  section: Section;
  name: Scalars["String"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
  active: Scalars["Boolean"];
  initialRanking: Scalars["Int"];
  qualifyGames: Scalars["Int"];
  topGames: Maybe<Scalars["Int"]>;
  records: Array<LeaderboardRecord>;
  prices: Array<LeaderboardPrice>;
  me: Maybe<LeaderboardRecord>;
  logo: Scalars["String"];
};

export type OpenLeaderboardRecordsArgs = {
  limit: Scalars["Int"];
};

export type OpenLeaderboardPrice = LeaderboardPrice & {
  from: Scalars["Int"];
  to: Scalars["Int"];
  amount: Amount;
};

export type OpenLeaderboardRecord = LeaderboardRecord & {
  id: Scalars["ID"];
  player: Player;
  win: Scalars["Int"];
  lose: Scalars["Int"];
  draw: Scalars["Int"];
  quit: Scalars["Int"];
  ranking: Scalars["Int"];
  gamesPlayed: Scalars["Int"];
  position: Scalars["Int"];
  leaderboard: Leaderboard;
  score: Scalars["Int"];
};

export type OthersChatUserListRequest = {
  name: Scalars["String"];
  page: Scalars["Int"];
  offset: Scalars["Int"];
};

export type PendingFacebookOrder = {
  requestId: Scalars["String"];
  productUrl: Scalars["String"];
};

export type PlacementConfig = {
  endOfGame: Scalars["Boolean"];
  scratchCards: Scalars["Boolean"];
  beginningOfGame: Scalars["Boolean"];
};

export type Player = GenericPlayer & {
  id: Scalars["ID"];
  name: Scalars["String"];
  acceptDirectMessages: Scalars["Boolean"];
  presence: Scalars["Boolean"];
  country: Maybe<Scalars["String"]>;
  balances: Array<Amount>;
  statistics: PlayerStatistics;
  activeTables: Array<Table>;
  friendship: FriendshipType;
  banning: BanningType;
  canSendChips: Maybe<CanSendChipsLimit>;
  vipUntil: Maybe<Scalars["DateTime"]>;
};

export type PlayerStatistics = {
  normal: Array<GameStatistics>;
  tournaments: Array<GameStatistics>;
};

export type Position = {
  x: Scalars["Int"];
  y: Scalars["Int"];
};

export type ProfilePhoto = {
  id: Scalars["ID"];
  userId: Scalars["ID"];
  created: Scalars["DateTime"];
  logo: Scalars["String"];
  removed: Maybe<Scalars["DateTime"]>;
};

export type ProfilePhotoInput = {
  id: Maybe<Scalars["ID"]>;
  file: Maybe<Scalars["Upload"]>;
};

export type Purchase = {
  user: User;
  chipPack: ChipPack;
  fundsTransferred: Scalars["Boolean"];
};

export type PurchaseActivityContent = {
  sell: Amount;
  purchased: Array<Amount>;
};

export type PurchasedScratchCard = {
  id: Scalars["ID"];
  winningCombination: Maybe<Scalars["Int"]>;
  displayId: Scalars["String"];
};

export type Query = {
  table: Maybe<Table>;
  hasBanningRelations: Maybe<Scalars["Boolean"]>;
  me: Maybe<User>;
  player: Maybe<Player>;
  playerByEmail: Maybe<Player>;
  chatToken: Maybe<Scalars["String"]>;
  othersChatUserList: Maybe<ChatUsers>;
  friendsChatUserList: Maybe<ChatUsers>;
  dailyActivityRewards: Maybe<Array<DailyActivityRewardItem>>;
  rooms: Maybe<Array<Room>>;
  room: Maybe<Room>;
  tournament: Maybe<Tournament>;
  leaderboard: Maybe<Leaderboard>;
  leaderboards: Maybe<Array<Leaderboard>>;
  virtualItems: Maybe<Array<VirtualItem>>;
  chipPacks: Maybe<Array<ChipPack>>;
  scratchCards: Maybe<Array<ScratchCard>>;
  referConfig: Maybe<ReferConfig>;
  marquee: Maybe<Array<Marquee>>;
  section: Maybe<Section>;
  profilePhoto: Maybe<ProfilePhoto>;
  mainScreenItemList: Maybe<Array<MainScreenItem>>;
  ticTacToeDefaultConfig: Maybe<TicTacToeDefaultConfig>;
  ticTacToeGetUsersToInvite: Maybe<Array<User>>;
  hangmanDefaultConfig: Maybe<HangmanDefaultConfig>;
  hangmanGetUsersToInvite: Maybe<Array<User>>;
  nextChallenge: Maybe<Scalars["DateTime"]>;
  dailyChallenges: Maybe<Array<ChallengeDailyStatistic>>;
  mpirimpaDefaultConfig: Maybe<MpirimpaDefaultConfig>;
  mpirimpaGetUsersToInvite: Maybe<Array<User>>;
  tichuDefaultConfig: Maybe<TichuDefaultConfig>;
  tichuGetUsersToInvite: Maybe<Array<User>>;
  beloteCoincheDefaultConfig: Maybe<BeloteCoincheDefaultConfig>;
  beloteCoincheGetUsersToInvite: Maybe<Array<User>>;
  backgammonDefaultConfig: Maybe<BackgammonDefaultConfig>;
  backgammonGetUsersToInvite: Maybe<Array<User>>;
};

export type QueryTableArgs = {
  id: Scalars["ID"];
};

export type QueryHasBanningRelationsArgs = {
  players: Array<Scalars["ID"]>;
};

export type QueryPlayerArgs = {
  id: Scalars["ID"];
};

export type QueryPlayerByEmailArgs = {
  email: Scalars["String"];
};

export type QueryOthersChatUserListArgs = {
  input: ChatUserListInput;
};

export type QueryFriendsChatUserListArgs = {
  input: ChatUserListInput;
};

export type QueryRoomArgs = {
  id: Scalars["ID"];
};

export type QueryTournamentArgs = {
  id: Scalars["ID"];
};

export type QueryLeaderboardArgs = {
  id: Scalars["ID"];
};

export type QueryLeaderboardsArgs = {
  filter: LeaderboardsFilter;
};

export type QueryReferConfigArgs = {
  input: ReferConfigInput;
};

export type QueryMarqueeArgs = {
  locale: Scalars["String"];
};

export type QuerySectionArgs = {
  id: Maybe<Scalars["ID"]>;
};

export type QueryProfilePhotoArgs = {
  id: Scalars["ID"];
};

export type QueryMainScreenItemListArgs = {
  category: SettingCategory;
};

export type QueryTicTacToeDefaultConfigArgs = {
  sectionId: Scalars["ID"];
};

export type QueryTicTacToeGetUsersToInviteArgs = {
  tableId: Scalars["ID"];
};

export type QueryHangmanDefaultConfigArgs = {
  sectionId: Scalars["ID"];
};

export type QueryHangmanGetUsersToInviteArgs = {
  tableId: Scalars["ID"];
};

export type QueryNextChallengeArgs = {
  sectionId: Scalars["ID"];
};

export type QueryDailyChallengesArgs = {
  input: Maybe<HangmanChallengesInput>;
};

export type QueryMpirimpaDefaultConfigArgs = {
  sectionId: Scalars["ID"];
};

export type QueryMpirimpaGetUsersToInviteArgs = {
  tableId: Scalars["ID"];
};

export type QueryTichuDefaultConfigArgs = {
  sectionId: Scalars["ID"];
};

export type QueryTichuGetUsersToInviteArgs = {
  tableId: Scalars["ID"];
};

export type QueryBeloteCoincheDefaultConfigArgs = {
  sectionId: Scalars["ID"];
};

export type QueryBeloteCoincheGetUsersToInviteArgs = {
  tableId: Scalars["ID"];
};

export type QueryBackgammonDefaultConfigArgs = {
  sectionId: Scalars["ID"];
};

export type QueryBackgammonGetUsersToInviteArgs = {
  tableId: Scalars["ID"];
};

export type ReferConfig = {
  referralBy: Array<Amount>;
  refer: Array<Amount>;
  redirectUrl: Scalars["String"];
};

export type ReferConfigInput = {
  callbackUrl: Scalars["String"];
  queryParameterName: Scalars["String"];
};

export type RewardsConfig = {
  currency: Currency;
  firstAd: Scalars["Int"];
  secondAd: Scalars["Int"];
  thirdAd: Scalars["Int"];
  fourthAd: Scalars["Int"];
  timeToWatchAdsInMinutes: Scalars["Int"];
  breakBetweenFirstAndSecondInMinutes: Scalars["Int"];
  breakBetweenSecondAndThirdInMinutes: Scalars["Int"];
  breakBetweenThirdAndFourthInMinutes: Scalars["Int"];
};

export type Room = {
  id: Scalars["ID"];
  name: Scalars["String"];
  logo: Scalars["String"];
  onlineUsers: Scalars["Int"];
  sections: Array<Section>;
  externalLink: Maybe<Scalars["String"]>;
  googlePlayLink: Maybe<Scalars["String"]>;
  appStoreLink: Maybe<Scalars["String"]>;
};

export type RoomChatMessageInput = {
  roomId: Scalars["ID"];
  message: Scalars["String"];
};

export type RoomChatMessages = {
  messages: Array<ChatMessage>;
};

export type RoomSectionCount = {
  sectionId: Scalars["ID"];
  count: Scalars["Int"];
};

export type RoomSectionsCount = {
  counters: Array<RoomSectionCount>;
};

export type RoomSubscriptionData =
  | RoomChatMessages
  | RoomUserCount
  | RoomSectionsCount;

export type RoomUserCount = {
  count: Scalars["Int"];
};

export type ScratchCard = {
  id: Scalars["ID"];
  name: Scalars["String"];
  mask: ScratchCardMask;
  coverSmall: ScratchCardDimensions;
  coverLarge: ScratchCardDimensions;
  background: ScratchCardDimensions;
  grid: ScratchCardGrid;
  combinations: Array<ScratchCardCombination>;
  price: Amount;
  symbolIndexes: Array<Scalars["String"]>;
  maximumCombination: ScratchCardCombination;
  minimumCombination: Maybe<ScratchCardCombination>;
  purchases: Array<PurchasedScratchCard>;
  path: Scalars["String"];
};

export type ScratchCardCombination = {
  count: Scalars["Int"];
  symbol: Scalars["String"];
  win: Amount;
  profitability: Scalars["Float"];
};

export type ScratchCardDimensions = Dimensions & {
  width: Scalars["Int"];
  height: Scalars["Int"];
};

export type ScratchCardGrid = Position &
  Dimensions & {
    rows: Scalars["Int"];
    columns: Scalars["Int"];
    x: Scalars["Int"];
    y: Scalars["Int"];
    width: Scalars["Int"];
    height: Scalars["Int"];
  };

export type ScratchCardMask = Position & {
  x: Scalars["Int"];
  y: Scalars["Int"];
};

export type Section = {
  id: Scalars["ID"];
  count: Scalars["Int"];
  type: Scalars["String"];
  tables: Tables;
  googlePlayLink: Maybe<Scalars["String"]>;
  appStoreLink: Maybe<Scalars["String"]>;
  logo: Maybe<Scalars["String"]>;
};

export type SectionTablesArgs = {
  after: Scalars["String"];
};

export type SectionTable = {
  operation: Maybe<TableOperation>;
  table: Table;
};

export type SectionTables = {
  tables: Array<SectionTable>;
};

export type SendBalanceInput = {
  playerId: Scalars["ID"];
  items: Array<SendBalanceInputType>;
};

export type SendBalanceInputType = {
  currency: Currency;
  amount: Scalars["Int"];
};

export type SendBalanceResponse = {
  userId: Scalars["ID"];
  balances: Array<Amount>;
};

export type SendMessageInput = {
  playerId: Scalars["ID"];
  message: Scalars["String"];
};

export enum SettingCategory {
  News = "NEWS",
}

export enum Statement {
  Points = "POINTS",
  Capot = "CAPOT",
  Double = "DOUBLE",
  Redouble = "REDOUBLE",
  Pass = "PASS",
}

export type SteamInitPurchaseInput = {
  chipPackId: Scalars["ID"];
  locale: Scalars["String"];
  tenant: Scalars["String"];
};

export type Subscription = {
  me: Maybe<UserUpdate>;
  tableInvitations: Maybe<TableInvitation>;
  joinRoom: Maybe<RoomSubscriptionData>;
  joinSection: Maybe<SectionTables>;
  joinTable: Maybe<TableSubscriptionData>;
};

export type SubscriptionJoinRoomArgs = {
  id: Scalars["ID"];
};

export type SubscriptionJoinSectionArgs = {
  id: Scalars["ID"];
};

export type SubscriptionJoinTableArgs = {
  id: Scalars["ID"];
};

export enum Suit {
  Club = "CLUB",
  Diamond = "DIAMOND",
  Heart = "HEART",
  Spade = "SPADE",
  None = "NONE",
}

export type Table = {
  id: Scalars["ID"];
  cursor: Scalars["String"];
  displayId: Scalars["String"];
  name: Scalars["String"];
  theme: Scalars["String"];
  created: Scalars["DateTime"];
  players: Array<Player>;
  section: Section;
  metaData: Scalars["JSON"];
  status: TableStatus;
  tournament: Maybe<Tournament>;
};

export type TableChatMessageInput = {
  tableId: Scalars["ID"];
  message: Scalars["String"];
};

export type TableChatMessages = {
  messages: Array<ChatMessage>;
};

export type TableDataMessage = {
  created: Scalars["DateTime"];
  data: Scalars["JSON"];
};

export type TableEdge = {
  node: Table;
};

export type TableInvitation = {
  id: Scalars["ID"];
  table: Table;
  from: Player;
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
};

export enum TableOperation {
  Create = "CREATE",
  Update = "UPDATE",
  Delete = "DELETE",
}

export enum TableStatus {
  Pending = "PENDING",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
}

export type TableSubscriptionData =
  | TableChatMessages
  | TableDataMessage
  | VirtualItemPurchasesMessage;

export type Tables = {
  edges: Array<TableEdge>;
  pageInfo: CursorPageInfo;
};

export type TicTacToeAnswerOnDoubleBetInput = {
  tableId: Scalars["ID"];
  answer: DoubleProposalAnswer;
};

export type TicTacToeAnswerOnRedoubleBetInput = {
  tableId: Scalars["ID"];
  answer: Maybe<DoubleProposalAnswer>;
};

export type TicTacToeDefaultConfig = {
  bet: TicTacToeDefaultConfigTuple;
  turnTime: TicTacToeDefaultConfigTuple;
  gridSize: TicTacToeDefaultConfigTuple;
  winsToComplete: TicTacToeDefaultConfigTuple;
  commissions: Array<CommissionConfig>;
};

export type TicTacToeDefaultConfigTuple = {
  minimum: Scalars["Int"];
  maximum: Scalars["Int"];
  default: Scalars["Int"];
  step: Scalars["Int"];
};

export type TicTacToeDoubleBetInput = {
  tableId: Scalars["ID"];
};

export type TicTacToeInvitePlayerInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type TicTacToeMakeMoveInput = {
  tableId: Scalars["ID"];
  box: Scalars["Int"];
};

export type TicTacToeNextRoundInput = {
  tableId: Scalars["ID"];
};

export type TicTacToeSetReadyInput = {
  tableId: Scalars["ID"];
};

export type TicTacToeTableInput = {
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: TicTacToeTableType;
  gridSize: Scalars["Int"];
  turnTime: Scalars["Int"];
  winsToComplete: Scalars["Int"];
  bet: Scalars["Int"];
};

export enum TicTacToeTableType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type TichuAnswerOnDoubleBetInput = {
  tableId: Scalars["ID"];
  answer: DoubleProposalAnswer;
};

export type TichuAnswerOnRedoubleBetInput = {
  tableId: Scalars["ID"];
  answer: Maybe<DoubleProposalAnswer>;
};

export type TichuBetByPointsConfig = {
  maxPoints: Scalars["Int"];
  minBet: Scalars["Int"];
};

export type TichuChangeSeatInput = {
  tableId: Scalars["ID"];
  seat: Scalars["Int"];
};

export type TichuDeclareInput = {
  tableId: Scalars["ID"];
};

export type TichuDefaultConfig = {
  bet: TichuDefaultConfigTuple;
  turnTime: TichuDefaultConfigTuple;
  points: TichuDefaultConfigTuple;
  betByPoints: Array<TichuBetByPointsConfig>;
  commissions: Array<CommissionConfig>;
};

export type TichuDefaultConfigTuple = {
  minimum: Scalars["Int"];
  maximum: Scalars["Int"];
  default: Scalars["Int"];
  step: Scalars["Int"];
};

export type TichuDoubleBetInput = {
  tableId: Scalars["ID"];
};

export type TichuInvitePlayerInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type TichuNextRoundInput = {
  tableId: Scalars["ID"];
};

export type TichuPassDragonInput = {
  tableId: Scalars["ID"];
  playerId: Scalars["ID"];
};

export type TichuPassInput = {
  tableId: Scalars["ID"];
};

export type TichuPlayInput = {
  tableId: Scalars["ID"];
  cards: Array<Scalars["String"]>;
  wish: Maybe<Scalars["Int"]>;
};

export type TichuPrepareBombInput = {
  tableId: Scalars["ID"];
};

export type TichuSendCardsInput = {
  tableId: Scalars["ID"];
  cards: Array<Scalars["String"]>;
};

export type TichuSetReadyInput = {
  tableId: Scalars["ID"];
};

export type TichuTableInput = {
  sectionId: Scalars["ID"];
  name: Scalars["String"];
  type: TichuTableType;
  bet: Scalars["Int"];
  turnTime: Scalars["Int"];
  points: Scalars["Int"];
};

export enum TichuTableType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type Tournament = Leaderboard & {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  section: Section;
  name: Scalars["String"];
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
  active: Scalars["Boolean"];
  initialRanking: Scalars["Int"];
  qualifyGames: Scalars["Int"];
  topGames: Maybe<Scalars["Int"]>;
  records: Array<LeaderboardRecord>;
  prices: Array<LeaderboardPrice>;
  amount: Amount;
  gamesPerPurchase: Scalars["Int"];
  me: Maybe<LeaderboardRecord>;
  logo: Scalars["String"];
  config: Scalars["JSON"];
};

export type TournamentRecordsArgs = {
  limit: Scalars["Int"];
};

export type TournamentParticipationUpdate = UserUpdate & {
  userId: Scalars["ID"];
  participationId: Scalars["String"];
  win: Scalars["Int"];
  lose: Scalars["Int"];
  draw: Scalars["Int"];
  quit: Scalars["Int"];
  ranking: Scalars["Int"];
  gamesPlayed: Scalars["Int"];
  gamesRemaining: Scalars["Int"];
};

export type TournamentPrice = LeaderboardPrice & {
  from: Scalars["Int"];
  to: Scalars["Int"];
  amount: Amount;
  potPercent: Maybe<Scalars["Float"]>;
};

export type TournamentRecord = LeaderboardRecord & {
  id: Scalars["ID"];
  player: Player;
  win: Scalars["Int"];
  lose: Scalars["Int"];
  draw: Scalars["Int"];
  quit: Scalars["Int"];
  ranking: Scalars["Int"];
  gamesPlayed: Scalars["Int"];
  score: Scalars["Int"];
  gamesRemaining: Scalars["Int"];
  position: Scalars["Int"];
  leaderboard: Leaderboard;
};

export type TournamentSection = Section & {
  id: Scalars["ID"];
  count: Scalars["Int"];
  type: Scalars["String"];
  tables: Tables;
  tournament: Tournament;
  googlePlayLink: Maybe<Scalars["String"]>;
  appStoreLink: Maybe<Scalars["String"]>;
  logo: Maybe<Scalars["String"]>;
};

export type TournamentSectionTablesArgs = {
  after: Scalars["String"];
};

export type Transaction = {
  id: Scalars["ID"];
  created: Scalars["DateTime"];
  balances: Array<Amount>;
  details: TransactionDetails;
  cursor: Scalars["String"];
};

export type TransactionDetails =
  | BalanceUpdateRollbackDetails
  | BalanceUpdateReceivedFromUserDetails
  | BalanceUpdateSendToUserDetails
  | BalanceUpdateVirtualItemGiftDetails
  | BalanceUpdateVirtualItemGiftInChatDetails
  | BalanceUpdateAvatarPurchaseDetails
  | BalanceUpdateScratchCardPurchaseDetails
  | BalanceUpdateScratchCardWinDetails
  | BalanceUpdateReferDetails
  | BalanceUpdateReferredByDetails
  | BalanceUpdateWelcomeDetails
  | BalanceUpdatePurchaseDetails
  | BalanceUpdateLeaderBoardAwardDetails
  | BalanceUpdateSystemGiftDetails
  | BalanceUpdateAdjustmentDetails
  | BalanceUpdateTopupDetails
  | BalanceUpdateTournamentPurchaseDetails
  | BalanceUpdateTournamentAwardDetails
  | BalanceUpdateRewardedAdDetails
  | BalanceUpdateHintDetails
  | ConsecutiveActivityRewardsConfigDetails
  | BalanceUpdateTableDetails;

export type TransactionEdge = {
  node: Transaction;
};

export type Transactions = {
  edges: Array<TransactionEdge>;
  pageInfo: CursorPageInfo;
};

export type TranslationInput = {
  text: Scalars["String"];
  language: Scalars["String"];
};

export type UseAvatarInput = {
  id: Scalars["ID"];
  avatarType: AvatarType;
};

export type User = GenericPlayer & {
  id: Scalars["ID"];
  name: Scalars["String"];
  vipUntil: Maybe<Scalars["DateTime"]>;
  presence: Scalars["Boolean"];
  country: Maybe<Scalars["String"]>;
  balances: Array<Amount>;
  statistics: PlayerStatistics;
  activeTables: Array<Table>;
  activities: Activities;
  transactions: Transactions;
  created: Scalars["DateTime"];
  nextFreeScratchCard: Maybe<Scalars["DateTime"]>;
  createdFriendRequests: Array<FriendRequest>;
  pendingFriendRequests: Array<FriendRequest>;
  friends: Friends;
  bannedUsers: BannedUsers;
  tournamentParticipations: Array<TournamentRecord>;
  avatars: Array<Avatar>;
  agreementGetEmail: Scalars["Boolean"];
  requestAgreement: Scalars["Boolean"];
  acceptDirectMessages: Scalars["Boolean"];
  isChatEnabled: Scalars["Boolean"];
  ads: Ads;
  chatTheme: ChatTheme;
  hasLoginsToday: Scalars["Boolean"];
};

export type UserActivitiesArgs = {
  after: Scalars["String"];
};

export type UserTransactionsArgs = {
  after: Scalars["String"];
};

export type UserFriendsArgs = {
  after: Scalars["String"];
};

export type UserBannedUsersArgs = {
  after: Scalars["String"];
};

export type UserMessageContent = {
  message: Scalars["String"];
};

export type UserReferContent = {
  initialized: Scalars["DateTime"];
};

export type UserUpdate = {
  userId: Scalars["ID"];
};

export type VirtualItem = {
  id: Scalars["ID"];
  name: Scalars["String"];
  amount: Amount;
  logo: Scalars["String"];
};

export type VirtualItemInChatPurchase = {
  id: Scalars["ID"];
  purchased: Scalars["DateTime"];
  from: Player;
  item: VirtualItem;
};

export type VirtualItemInChatPurchaseInput = {
  chatId: Scalars["ID"];
  item: Scalars["String"];
};

export type VirtualItemPurchase = {
  id: Scalars["ID"];
  purchased: Scalars["DateTime"];
  from: Player;
  to: Player;
  item: VirtualItem;
};

export type VirtualItemPurchaseInput = {
  tableId: Scalars["ID"];
  to: Array<Scalars["String"]>;
  item: Scalars["String"];
};

export type VirtualItemPurchasesMessage = {
  purchases: Array<VirtualItemPurchase>;
};

export type VivaOrderInput = {
  chipPackId: Scalars["ID"];
  locale: Scalars["String"];
  sourceName: Scalars["String"];
};
